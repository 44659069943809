import React from "react"

import { GatsbyImage } from "gatsby-plugin-image"
import { Image } from "components/anti/image/image"

export const AcfImage = ({ data }) => {
  const block = data && data.image
  // console.log("inside acf", block)
  return (
    <div className={`mb-3 acf-image ${data.attributes.className || ""}`}>
      <img
        src={block.image.sourceUrl}
        alt={
          block.image.altText || block.image.title || block.caption || "Core"
        }
        className="w-100 my-3"
      />
      {/* <GatsbyImage
        image={block.image.sourceUrl}
        alt={
          block.image.altText || block.image.title || block.caption || "Core"
        }
        className="img-fluid w-100 mb-2"
      /> */}
      {/* {block.caption && (
        <small className="text-muted text-center d-block">
          {block.caption}
        </small>
      )} */}
    </div>
  )
}
