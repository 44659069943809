import React, { useLayoutEffect, useState } from "react"
import moment from "moment"

import Layout from "components/layout"
import SEO from "components/seo"

import { Button } from "components/anti/buttons/buttons"
import { Image } from "components/anti/image/image"

import { AcfImage } from "components/gutenberg/acf-image"
import { CoreGroup } from "components/gutenberg/core-group"
import { CoreHeading } from "components/gutenberg/core-heading"
import { CoreList } from "components/gutenberg/core-list"
import { CoreParagraph } from "components/gutenberg/core-paragraph"
import { CoreShortcode } from "components/gutenberg/shortcode"

import { NewsDetailsCover } from "components/pages/news/detail/cover"

const PostsTemplate = ({ pageContext, location }) => {
  const data = pageContext.data
  const blocks = pageContext.data.blocks
  const cover = data.featuredImage?.node?.sourceUrl

  useLayoutEffect(() => {
    // ACF Cover Main conditional
    const navbar = document.querySelector(".navbar")
    if (blocks && blocks[0] && blocks[0].name !== "acf/cover-main") {
      navbar.classList.remove("transparent")
      navbar.classList.replace("dark", "light")
    }
  }, [blocks])

  const showDate =
    data.slug === "kebijakan-privasi" || data.slug === "syarat-ketentuan"
      ? false
      : true

  const backLoc = location.pathname.split("/")

  // console.log("data", data)
  // console.log("backLoc", backLoc)
  return (
    <Layout>
      <SEO
        title={data.seo.title}
      // description={data.seo.opengraphDescription} // Experiment
      // image={data.seo.opengraphImage.sourceUrl} // Experiment
      />
      {cover && backLoc[1] === "aman-update" && <NewsDetailsCover data={cover} btnBack={backLoc[1]} />}
      <section className="pb-main pt-4">
        <div className="container mw-lg">
          {backLoc[1] === "aman-update" || backLoc[1] === "program" ? (
            ""
          )
            :
            (
              <Button
                variant="link"
                className="btn-back-help ai-arrow-from-left mb-3 mb-md-4"
                link={"/"}
              >
                Kembali
              </Button>
            )}
          {backLoc[1] === "program" && (
            <>
              <Button
                variant="link"
                className="btn-back-help ai-arrow-from-left mb-3 mb-md-4"
                link={"/program"}
              >
                Kembali
              </Button>
              <Image src={cover} ratio="r-16-9" alt="cover" className="fadeIn mb-5" />
            </>
          )}
          <div className="post-header">
            {showDate && (
              <p className="date text-muted mb-0">
                {moment(data.date).format("MMM DD, YYYY")}
              </p>
            )}
            <h1 className="title">{data.title}</h1>
          </div>
          <div className="post-content">
            {blocks &&
              blocks.map((block, i) => {
                switch (block.name) {
                  case "acf/image":
                    return <AcfImage data={block} key={i} /> // Meant to be used to replace Core Image
                  case "core/group":
                    return <CoreGroup data={block} key={i} />
                  case "core/heading":
                    return <CoreHeading data={block} key={i} />
                  case "core/list":
                    return <CoreList data={block} key={i} />
                  case "core/paragraph":
                    return <CoreParagraph data={block} key={i} />
                  case "core/shortcode":
                    // prettier-ignore
                    return <CoreShortcode data={block} pageContext={pageContext} key={i} />
                  default:
                    return <div key={i} />
                }
              })}
          </div>
          <div className="btn-group">
            {data?.programDetails?.buttons?.list
              && data.programDetails.buttons.list.map((btn, i) => {
                return (
                  <div className="d-block mb-3" key={i}>
                    <Button variant="primary" link={btn?.url} >
                      {btn?.text}
                    </Button>
                  </div>
                )
              })}
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default PostsTemplate
