import React from "react"

import { Cover } from "components/anti/cover/cover"
import { Button } from "components/anti/buttons/buttons"

export const NewsDetailsCover = ({ data, btnBack }) => {

  const btn = (
    <div className="breadcrumb mw-lg">
      <Button
        variant="link"
        className="btn-back-help ai-arrow-from-left"
        link={`/${btnBack}`}
      >
        Kembali
      </Button>
    </div>
  )

  return (
    <Cover
      breadcrumb={btn}
      variant="basic"
      theme="dark"
      img={data}
      imgHeight="h-300px h-lg-450px w-100"
      className="animated fadeInUp mb-5 cover-full"
    />
  )
}
